exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-risk-mitigation-product-diligence-js": () => import("./../../../src/pages/articles/risk-mitigation/product-diligence.js" /* webpackChunkName: "component---src-pages-articles-risk-mitigation-product-diligence-js" */),
  "component---src-pages-articles-value-acceleration-product-roadmap-js": () => import("./../../../src/pages/articles/value-acceleration/product-roadmap.js" /* webpackChunkName: "component---src-pages-articles-value-acceleration-product-roadmap-js" */),
  "component---src-pages-better-diligence-js": () => import("./../../../src/pages/better-diligence.js" /* webpackChunkName: "component---src-pages-better-diligence-js" */),
  "component---src-pages-case-studies-healthcare-client-js": () => import("./../../../src/pages/case-studies/healthcare-client.js" /* webpackChunkName: "component---src-pages-case-studies-healthcare-client-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-diligence-process-js": () => import("./../../../src/pages/diligence-process.js" /* webpackChunkName: "component---src-pages-diligence-process-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-risk-mitigation-js": () => import("./../../../src/pages/risk-mitigation.js" /* webpackChunkName: "component---src-pages-risk-mitigation-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-value-acceleration-js": () => import("./../../../src/pages/value-acceleration.js" /* webpackChunkName: "component---src-pages-value-acceleration-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

